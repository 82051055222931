import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import { PrismicRichText } from "@prismicio/react"

import LayoutUpdate from "../../components/layout/layout-update"
import SEOnew from "../../components/seo-new"
import htmlSerializer from "../../utils/htmlSerializer"
import CommitmentSignature from "../../components/sections/CommitmentSignature"
import useWindowSize from "../../utils/useWindowSize"
import HeroSectionWithTitle from "../../components/common/HeroSectionWithTitle"

const CareersPage = () => {
  const screen = useWindowSize()

  const data = useStaticQuery(graphql`
    query CareersPageQuery {
      prismicBasePage(uid: { eq: "careers" }) {
        data {
          title
          hero_image {
            gatsbyImageData
          }
          content {
            richText
          }
        }
      }
      imageSharp(fluid: { originalName: { eq: "group-construct.png" } }) {
        gatsbyImageData(width: 1000)
      }
    }
  `)

  const { title, hero_image, content } = data.prismicBasePage.data
  const image = getImage(hero_image)
  return (
    <LayoutUpdate>
      <SEOnew title={title} />
      <HeroSectionWithTitle
        title={"Careers"}
        image={image}
        imagePosition={"50% 75%"}
      />

      <div className="w-full flex items-center justify-center flex-col mb-12 pt-4 md:pt-12">
        <div className="services-content w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col md:items-center xl:items-start justify-center pt-8">
          <PrismicRichText
            field={content.richText}
            components={htmlSerializer}
          />
        </div>
      </div>
    </LayoutUpdate>
  )
}

export default CareersPage
